.custom-toggle .MuiToggleButton-sizeSmall {
  border: 1.8px solid #bdc3c7 !important;
  border-radius: 10px !important;
  height: 40px;
  width: 120px;
}

.custom-toggle .MuiToggleButton-root.Mui-selected {
  border: 1.55px solid #3867d6 !important;
  background-color: rgba(56, 103, 214, 0.2) !important;
}

.schedule-toggle .MuiToggleButton-sizeSmall {
  border: 1.8px solid #bdc3c7 !important;

  border-radius: 10px !important;
  height: 80px !important;
  width: 70px !important;
}

.schedule-toggle .MuiToggleButton-root.Mui-selected {
  border: 1.55px solid #3867d6 !important;
  background-color: rgba(56, 103, 214, 0.2) !important;
}

.schedule-toggle .MuiToggleButton-label {
  display: flex !important;
  flex-direction: column !important;
}

.textDate {
  color: black;
  font-size: 15px;
  text-transform: uppercase;
}

.time-toggle .MuiToggleButton-sizeSmall {
  border: 1.8px solid #bdc3c7 !important;

  border-radius: 10px !important;
  height: 40px;
  width: 150px !important;
}

.time-toggle .MuiToggleButton-root.Mui-selected {
  border: 1.55px solid #3867d6 !important;
  background-color: rgba(56, 103, 214, 0.2) !important;
}

/* 
.time-toggle .MuiToggleButton-sizeSmall {
  height: 40px;
  width: 150px !important;
} */
