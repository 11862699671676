@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Pacifico&display=swap");
.container {
  width: 100%;
  min-height: 100vh;
  background-image: url("../assets/flag.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(15, 84, 131, 0.445);
  background-position: center;
}
.overlay {
  background: rgba(0, 0, 0, 0.637);
  min-height: 100vh;
  width: 100%;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  color: white;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
.iconsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.realIcon {
  margin-right: 20px;
  height: 40px;
  width: 40px;
}

.navbar {
  height: 70px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.336);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.menuLink {
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-weight: 600;
}

.menuLink:hover {
  color: #74b9ff;
}

.navBtn {
  background-color: #0984e3;
  height: 100%;
  width: 350px;
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .iconsContainer {
    display: none;
  }

  .navbar {
    display: none;
  }

  .iconsContainerMobile {
    color: white;
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
}

/* Courses */

.courseContainer {
  min-height: 100vh;
  /* background-color: #ecf0f1; */
}

.textContainer {
  text-align: center;
  padding-top: 80px;
}

.navText {
  font-size: 14px;
  font-weight: bold;
  margin-left: 25px;
}
