.booking-form-input .ant-form-item-control-input-content {
  display: flex !important;
  flex-direction: row !important;
}

.booking-form-input .ant-radio-checked .ant-radio-inner {
  border-color: #670016 !important ;
}

.booking-form-input .ant-radio-checked .ant-radio-inner:after {
  background-color: #670016;
}

.booking-form-input .ant-radio:hover .ant-radio-inner {
  border-color: #670016;
}
.react-tel-input .form-control {
  height: 50px;
  width: 97.5%;
  /* margin-left: 10px; */
}
.react-tel-input .selected-flag {
  width: 45px;
  /* margin-left: 20px; */
}
.react-tel-input .country-list .country-name {
  margin-left: 30px;
}
