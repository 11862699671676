.navigation__checkbox {
  display: none;
}
.navigation__button {
  background-color: white;
  height: 40px;
  width: 40px;
  position: fixed;
  top: 2rem;
  right: 1rem;
  border-radius: 50%;
  /* z-index: 2000; */
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
}
.navigation__background {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: fixed;
  top: 2rem;
  right: 1rem;
  background-image: radial-gradient(#3867d6, #547ddc);
  /* z-index: 1000; */
  transition: transform 0.8s cubic-bezier(0.83, 0, 0.17, 1);
}
.navigation__nav {
  height: 100vh;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 1500; */
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.navigation__list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
}
.navigation__item {
  margin: 1rem;
}
.navigation__link:link,
.navigation__link:visited {
  display: inline-block;
  font-size: 1rem;
  padding: 1rem 0rem;
  font-weight: 300;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  width: auto;
  background-size: 220%;
  transition: all 0.4s;
}
.navigation__link:link span,
.navigation__link:visited span {
  margin-right: 0rem;
  display: inline-block;
}
.navigation__link:hover,
.navigation__link:active {
  background-position: 100%;
  color: #55c57a;
  transform: translateX(1rem);
}
.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(80);
}
.navigation__checkbox:checked ~ .navigation__nav {
  opacity: 1;
  width: 100%;
}
.navigation__icon {
  position: relative;
  margin-top: 30px;
}
.navigation__icon,
.navigation__icon::before,
.navigation__icon::after {
  width: 20px;
  height: 2px;
  background-color: #333;
  display: inline-block;
}
.navigation__icon::before,
.navigation__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}
.navigation__icon::before {
  top: -0.8rem;
}
.navigation__icon::after {
  top: 0.8rem;
}
.navigation__button:hover .navigation__icon::before {
  top: -1rem;
}
.navigation__button:hover .navigation__icon::after {
  top: 1rem;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  transform: rotate(135deg);
  top: 0;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  transform: rotate(-135deg);
  top: 0;
}
