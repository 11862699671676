@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap);
.custom-toggle .MuiToggleButton-sizeSmall {
  border: 1.8px solid #bdc3c7 !important;
  border-radius: 10px !important;
  height: 40px;
  width: 120px;
}

.custom-toggle .MuiToggleButton-root.Mui-selected {
  border: 1.55px solid #3867d6 !important;
  background-color: rgba(56, 103, 214, 0.2) !important;
}

.schedule-toggle .MuiToggleButton-sizeSmall {
  border: 1.8px solid #bdc3c7 !important;

  border-radius: 10px !important;
  height: 80px !important;
  width: 70px !important;
}

.schedule-toggle .MuiToggleButton-root.Mui-selected {
  border: 1.55px solid #3867d6 !important;
  background-color: rgba(56, 103, 214, 0.2) !important;
}

.schedule-toggle .MuiToggleButton-label {
  display: flex !important;
  flex-direction: column !important;
}

.textDate {
  color: black;
  font-size: 15px;
  text-transform: uppercase;
}

.time-toggle .MuiToggleButton-sizeSmall {
  border: 1.8px solid #bdc3c7 !important;

  border-radius: 10px !important;
  height: 40px;
  width: 150px !important;
}

.time-toggle .MuiToggleButton-root.Mui-selected {
  border: 1.55px solid #3867d6 !important;
  background-color: rgba(56, 103, 214, 0.2) !important;
}

/* 
.time-toggle .MuiToggleButton-sizeSmall {
  height: 40px;
  width: 150px !important;
} */

.styles_container__HbBoH {
  width: 100%;
  min-height: 100vh;
  background-image: url(/static/media/flag.9b243b6e.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(15, 84, 131, 0.445);
  background-position: center;
}
.styles_overlay__1_lNf {
  background: rgba(0, 0, 0, 0.637);
  min-height: 100vh;
  width: 100%;
}
.styles_headerContainer__3HKXB {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  color: white;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
.styles_iconsContainer__3Bu0G {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.styles_icons__1WgM7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.styles_realIcon__1NESj {
  margin-right: 20px;
  height: 40px;
  width: 40px;
}

.styles_navbar__ll-A4 {
  height: 70px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.336);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.styles_menuLink__36FS9 {
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-weight: 600;
}

.styles_menuLink__36FS9:hover {
  color: #74b9ff;
}

.styles_navBtn__2iH3_ {
  background-color: #0984e3;
  height: 100%;
  width: 350px;
  -webkit-clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
          clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .styles_iconsContainer__3Bu0G {
    display: none;
  }

  .styles_navbar__ll-A4 {
    display: none;
  }

  .styles_iconsContainerMobile__1Suul {
    color: white;
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
}

/* Courses */

.styles_courseContainer__1IfkT {
  min-height: 100vh;
  /* background-color: #ecf0f1; */
}

.styles_textContainer__5cjMI {
  text-align: center;
  padding-top: 80px;
}

.styles_navText__1epfE {
  font-size: 14px;
  font-weight: bold;
  margin-left: 25px;
}

.navigation__checkbox {
  display: none;
}
.navigation__button {
  background-color: white;
  height: 40px;
  width: 40px;
  position: fixed;
  top: 2rem;
  right: 1rem;
  border-radius: 50%;
  /* z-index: 2000; */
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
}
.navigation__background {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: fixed;
  top: 2rem;
  right: 1rem;
  background-image: radial-gradient(#3867d6, #547ddc);
  /* z-index: 1000; */
  transition: transform 0.8s cubic-bezier(0.83, 0, 0.17, 1);
}
.navigation__nav {
  height: 100vh;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 1500; */
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.navigation__list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
}
.navigation__item {
  margin: 1rem;
}
.navigation__link:link,
.navigation__link:visited {
  display: inline-block;
  font-size: 1rem;
  padding: 1rem 0rem;
  font-weight: 300;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  width: auto;
  background-size: 220%;
  transition: all 0.4s;
}
.navigation__link:link span,
.navigation__link:visited span {
  margin-right: 0rem;
  display: inline-block;
}
.navigation__link:hover,
.navigation__link:active {
  background-position: 100%;
  color: #55c57a;
  transform: translateX(1rem);
}
.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(80);
}
.navigation__checkbox:checked ~ .navigation__nav {
  opacity: 1;
  width: 100%;
}
.navigation__icon {
  position: relative;
  margin-top: 30px;
}
.navigation__icon,
.navigation__icon::before,
.navigation__icon::after {
  width: 20px;
  height: 2px;
  background-color: #333;
  display: inline-block;
}
.navigation__icon::before,
.navigation__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}
.navigation__icon::before {
  top: -0.8rem;
}
.navigation__icon::after {
  top: 0.8rem;
}
.navigation__button:hover .navigation__icon::before {
  top: -1rem;
}
.navigation__button:hover .navigation__icon::after {
  top: 1rem;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  transform: rotate(135deg);
  top: 0;
}
.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  transform: rotate(-135deg);
  top: 0;
}

.react-tel-input .form-control {
  background: transparent !important;
  border: 1px solid #95a5a6;
  width: 100%;
}

.react-tel-input .special-label {
  display: none;
}

@media only screen and (max-width: 600px) {
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}

.booking-form-input .ant-form-item-control-input-content {
  display: flex !important;
  flex-direction: row !important;
}

.booking-form-input .ant-radio-checked .ant-radio-inner {
  border-color: #670016 !important ;
}

.booking-form-input .ant-radio-checked .ant-radio-inner:after {
  background-color: #670016;
}

.booking-form-input .ant-radio:hover .ant-radio-inner {
  border-color: #670016;
}
.react-tel-input .form-control {
  height: 50px;
  width: 97.5%;
  /* margin-left: 10px; */
}
.react-tel-input .selected-flag {
  width: 45px;
  /* margin-left: 20px; */
}
.react-tel-input .country-list .country-name {
  margin-left: 30px;
}

.booking-button{
    height: 50px;
    color: white;
    border-radius: 20px;
    padding: 20px 10px;
}
* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}
.App {
  height: 100vh;
  font-size: 1rem;
}
#chat-circle {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: #27ae60;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: white;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  z-index: 10000000;
}
.hide--bot {
  display: none;
}
.chatbot {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 640px) {
  .chatbot {
    max-width: 380px;
    right: 50px;
    bottom: 50px;
    height: 80vh;
    top: auto;
  }
}
.chatbot.chatbot--closed {
  top: auto;
  width: 100%;
}
.chat-height {
  height: 360px;
}
@media screen and (min-width: 640px) {
  .chat-height {
    height: 360px;
  }
}
.chatbot__header {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #27ae60;
  height: 54px;
  padding: 0 20px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
@media screen and (min-width: 640px) {
  .chatbot__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.chatbot__header:hover {
  background-color: #36d278;
}
.chatbot__header p {
  margin-right: 20px;
}
.chathome__header {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #27ae60;
  padding: 10px;
  width: 100%;
  transition: background-color 0.2s ease;
  height: 70px;
}
@media screen and (min-width: 640px) {
  .chathome__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.chathome__header h3 {
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 1.4rem;
  text-align: center;
  color: #fff;
  margin-top: 10px;
}
.chatbot__close-button {
  fill: #fff;
}
.chatbot__close-button.icon-speech {
  width: 20px;
  display: none;
}
.chatbot--closed .chatbot__close-button.icon-speech {
  display: block;
}
.chatbot__close-button.icon-close {
  width: 14px;
}
.chatbot--closed .chatbot__close-button.icon-close {
  display: none;
}
.chatbot__message-window {
  height: calc(100% - 140px);
  padding: 40px 20px 20px;
  background-color: #fff;
  overflow-x: none;
  overflow-y: auto;
  background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
  background-repeat: repeat;
  background-position: center;
}
.chatbot__message-window::-webkit-scrollbar {
  display: none;
}
.chatbot--closed .chatbot__message-window {
  display: none;
}
.chathome__message-window {
  padding: 10px 20px 2px 20px;
  background-color: #fff;
  overflow-x: none;
  flex-grow: 1;
}
.chathome__message-window::-webkit-scrollbar {
  display: none;
}
.chatbot--closed .chathome__message-window {
  display: none;
}
.chatbot_logo {
  width: 241px;
  height: 98%;
  text-align: center;
}
.chatbot__messages {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: auto;
}
.chatbot__messages li {
  margin-bottom: 20px;
}
.chatbot__messages li.is-ai {
  display: inline-flex;
  align-items: flex-start;
}
.chatbot__messages li.is-user {
  text-align: right;
  display: inline-flex;
  align-self: flex-end;
}
.chatbot__messages li .is-ai__profile-picture {
  margin-right: 8px;
}
.chatbot__messages li .is-ai__profile-picture svg {
  width: 36px;
  height: 36px;
  padding-top: 7px;
}
.chatbot__message {
  display: inline-block;
  padding: 12px 20px;
  word-break: break-word;
  margin: 0;
  border-radius: 6px;
  letter-spacing: -0.01em;
  line-height: 1.45;
  position: relative;
}
.is-ai .chatbot__message {
  background-color: white;
  margin-right: 30px;
}
.is-user {
  position: relative;
}
.is-user .chatbot__message {
  background-color: #dcf8c6;
  margin-left: 30px;
}
.chatbot__message a {
  color: #f39c12;
  word-break: break-all;
  display: inline-block;
}
.chatbot__message p:first-child {
  margin-top: 0;
}
.chatbot__message p {
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.chatbot__message p:last-child {
  margin-bottom: 0;
}
.chatbot__message button {
  /* background-color: #fff;
  font-weight: 300;
  border: 2px solid #f39c12;
  border-radius: 50px;
  padding: 8px 20px;
  margin: -8px 10px 18px 0;
  transition: background-color 0.2s ease; */
  cursor: pointer;
}
.chatbot__message button:hover {
  background-color: #f2f2f2;
}
.chatbot__message button:focus {
  outline: none;
}
.chatbot__message img {
  max-width: 100%;
}
.chatbot__message .card {
  background-color: #fff;
  text-decoration: none;
  overflow: hidden;
  border-radius: 6px;
  color: black;
  word-break: normal;
}
.chatbot__message .card .card-content {
  padding: 20px;
}
.chatbot__message .card .card-title {
  margin-top: 0;
}
.chatbot__message .card .card-button {
  color: #f39c12;
  text-decoration: underline;
}
.animation:last-child {
  animation: fadein 0.25s;
  animation-timing-function: all 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.chatbot__arrow {
  width: 0;
  height: 0;
  margin-top: 18px;
}
.chatbot__arrow--right {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #dcf8c6;
}
.chatbot__arrow--left {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid white;
}
.chatbot__entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 20px;
  border-top: 1px solid #e6eaee;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.chatbot--closed .chatbot__entry {
  display: none;
}
.chathome__entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 10px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: auto;
}
.chatbot__input {
  height: 100%;
  width: 80%;
  border: 0;
}
.chatbot__input:focus {
  outline: none;
}
.chatbot__input::-webkit-input-placeholder {
  color: #7f7f7f;
}
.chatbot__input::-moz-placeholder {
  color: #7f7f7f;
}
.chatbot__input::-ms-input-placeholder {
  color: #7f7f7f;
}
.chatbot__input::-moz-placeholder {
  color: #7f7f7f;
}
.chatbot__submit {
  fill: #f39c12;
  height: 22px;
  width: 22px;
  transition: fill 0.2s ease;
  cursor: pointer;
}
.chatbot__submit:hover {
  fill: #976008;
}
.u-text-highlight {
  color: #dcf8c6;
}
.loader {
  margin-bottom: -2px;
  text-align: center;
  opacity: 0.3;
}
.loader__dot {
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  margin: 0 1px;
  background: black;
  border-radius: 50px;
  animation: loader 0.45s infinite alternate;
}
.loader__dot:nth-of-type(2) {
  animation-delay: 0.15s;
}
.loader__dot:nth-of-type(3) {
  animation-delay: 0.35s;
}
@keyframes loader {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
    margin-top: 10px;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-top: 0;
    margin-bottom: 10px;
  }
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #27ae60;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}
@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #27ae60;
  }
  28% {
    transform: translateY(-7px);
    background-color: #9ecab9;
  }
  44% {
    transform: translateY(0px);
    background-color: #b5d9cb;
  }
}
.chatbot__chatname {
  font-size: 10px;
  color: #000;
  font-weight: bolder;
  letter-spacing: 1px;
}
.MuiAvatar-colorDefault {
  color: #fafafa;
  background-color: #27ae60 !important;
}
.username-main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100px;
}

.username-btn {
  margin-top: 10px;
}

.username-btns {
  display: flex;
  justify-content: space-around;
}
#username-inp-label {
  font-size: inherit;
}

.custom-reply-bot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat--time {
  margin-left: 10px;
  font-size: xx-small;
  position: absolute;
  right: 5px;
  bottom: 2px;
}

/* @font-face {
  font-family: gilroy;
  src: url(./assets/gilroy/Gilroy-Medium.ttf),
    url(./assets/gilroy/Gilroy-Regular.ttf),
    url(./assets/gilroy/Gilroy-SemiBold.ttf);
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: gilroy !important; */
  font-family: "Montserrat", sans-serif !important;
}

html {
  font-family: "Montserrat", sans-serif !important;
}
.Carousel-button-127 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
p {
  margin-bottom: 0px !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  /* font: 700 18px/1 "Lato", sans-serif; */
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  /* text-transform: uppercase; */
  font-weight: bold;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

