.react-tel-input .form-control {
  background: transparent !important;
  border: 1px solid #95a5a6;
  width: 100%;
}

.react-tel-input .special-label {
  display: none;
}

@media only screen and (max-width: 600px) {
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}
