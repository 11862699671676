@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");

/* @font-face {
  font-family: gilroy;
  src: url(./assets/gilroy/Gilroy-Medium.ttf),
    url(./assets/gilroy/Gilroy-Regular.ttf),
    url(./assets/gilroy/Gilroy-SemiBold.ttf);
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: gilroy !important; */
  font-family: "Montserrat", sans-serif !important;
}

html {
  font-family: "Montserrat", sans-serif !important;
}
.Carousel-button-127 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
p {
  margin-bottom: 0px !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  /* font: 700 18px/1 "Lato", sans-serif; */
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  /* text-transform: uppercase; */
  font-weight: bold;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
