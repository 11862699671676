* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}
.App {
  height: 100vh;
  font-size: 1rem;
}
#chat-circle {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: #27ae60;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: white;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  z-index: 10000000;
}
.hide--bot {
  display: none;
}
.chatbot {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 640px) {
  .chatbot {
    max-width: 380px;
    right: 50px;
    bottom: 50px;
    height: 80vh;
    top: auto;
  }
}
.chatbot.chatbot--closed {
  top: auto;
  width: 100%;
}
.chat-height {
  height: 360px;
}
@media screen and (min-width: 640px) {
  .chat-height {
    height: 360px;
  }
}
.chatbot__header {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #27ae60;
  height: 54px;
  padding: 0 20px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
@media screen and (min-width: 640px) {
  .chatbot__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.chatbot__header:hover {
  background-color: #36d278;
}
.chatbot__header p {
  margin-right: 20px;
}
.chathome__header {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #27ae60;
  padding: 10px;
  width: 100%;
  transition: background-color 0.2s ease;
  height: 70px;
}
@media screen and (min-width: 640px) {
  .chathome__header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.chathome__header h3 {
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 1.4rem;
  text-align: center;
  color: #fff;
  margin-top: 10px;
}
.chatbot__close-button {
  fill: #fff;
}
.chatbot__close-button.icon-speech {
  width: 20px;
  display: none;
}
.chatbot--closed .chatbot__close-button.icon-speech {
  display: block;
}
.chatbot__close-button.icon-close {
  width: 14px;
}
.chatbot--closed .chatbot__close-button.icon-close {
  display: none;
}
.chatbot__message-window {
  height: calc(100% - 140px);
  padding: 40px 20px 20px;
  background-color: #fff;
  overflow-x: none;
  overflow-y: auto;
  background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
  background-repeat: repeat;
  background-position: center;
}
.chatbot__message-window::-webkit-scrollbar {
  display: none;
}
.chatbot--closed .chatbot__message-window {
  display: none;
}
.chathome__message-window {
  padding: 10px 20px 2px 20px;
  background-color: #fff;
  overflow-x: none;
  flex-grow: 1;
}
.chathome__message-window::-webkit-scrollbar {
  display: none;
}
.chatbot--closed .chathome__message-window {
  display: none;
}
.chatbot_logo {
  width: 241px;
  height: 98%;
  text-align: center;
}
.chatbot__messages {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: auto;
}
.chatbot__messages li {
  margin-bottom: 20px;
}
.chatbot__messages li.is-ai {
  display: inline-flex;
  align-items: flex-start;
}
.chatbot__messages li.is-user {
  text-align: right;
  display: inline-flex;
  align-self: flex-end;
}
.chatbot__messages li .is-ai__profile-picture {
  margin-right: 8px;
}
.chatbot__messages li .is-ai__profile-picture svg {
  width: 36px;
  height: 36px;
  padding-top: 7px;
}
.chatbot__message {
  display: inline-block;
  padding: 12px 20px;
  word-break: break-word;
  margin: 0;
  border-radius: 6px;
  letter-spacing: -0.01em;
  line-height: 1.45;
  position: relative;
}
.is-ai .chatbot__message {
  background-color: white;
  margin-right: 30px;
}
.is-user {
  position: relative;
}
.is-user .chatbot__message {
  background-color: #dcf8c6;
  margin-left: 30px;
}
.chatbot__message a {
  color: #f39c12;
  word-break: break-all;
  display: inline-block;
}
.chatbot__message p:first-child {
  margin-top: 0;
}
.chatbot__message p {
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.chatbot__message p:last-child {
  margin-bottom: 0;
}
.chatbot__message button {
  /* background-color: #fff;
  font-weight: 300;
  border: 2px solid #f39c12;
  border-radius: 50px;
  padding: 8px 20px;
  margin: -8px 10px 18px 0;
  transition: background-color 0.2s ease; */
  cursor: pointer;
}
.chatbot__message button:hover {
  background-color: #f2f2f2;
}
.chatbot__message button:focus {
  outline: none;
}
.chatbot__message img {
  max-width: 100%;
}
.chatbot__message .card {
  background-color: #fff;
  text-decoration: none;
  overflow: hidden;
  border-radius: 6px;
  color: black;
  word-break: normal;
}
.chatbot__message .card .card-content {
  padding: 20px;
}
.chatbot__message .card .card-title {
  margin-top: 0;
}
.chatbot__message .card .card-button {
  color: #f39c12;
  text-decoration: underline;
}
.animation:last-child {
  animation: fadein 0.25s;
  animation-timing-function: all 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.chatbot__arrow {
  width: 0;
  height: 0;
  margin-top: 18px;
}
.chatbot__arrow--right {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #dcf8c6;
}
.chatbot__arrow--left {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid white;
}
.chatbot__entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 20px;
  border-top: 1px solid #e6eaee;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.chatbot--closed .chatbot__entry {
  display: none;
}
.chathome__entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 10px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: auto;
}
.chatbot__input {
  height: 100%;
  width: 80%;
  border: 0;
}
.chatbot__input:focus {
  outline: none;
}
.chatbot__input::-webkit-input-placeholder {
  color: #7f7f7f;
}
.chatbot__input::-moz-placeholder {
  color: #7f7f7f;
}
.chatbot__input::-ms-input-placeholder {
  color: #7f7f7f;
}
.chatbot__input::-moz-placeholder {
  color: #7f7f7f;
}
.chatbot__submit {
  fill: #f39c12;
  height: 22px;
  width: 22px;
  transition: fill 0.2s ease;
  cursor: pointer;
}
.chatbot__submit:hover {
  fill: #976008;
}
.u-text-highlight {
  color: #dcf8c6;
}
.loader {
  margin-bottom: -2px;
  text-align: center;
  opacity: 0.3;
}
.loader__dot {
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  margin: 0 1px;
  background: black;
  border-radius: 50px;
  animation: loader 0.45s infinite alternate;
}
.loader__dot:nth-of-type(2) {
  animation-delay: 0.15s;
}
.loader__dot:nth-of-type(3) {
  animation-delay: 0.35s;
}
@keyframes loader {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
    margin-top: 10px;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-top: 0;
    margin-bottom: 10px;
  }
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #27ae60;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}
@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #27ae60;
  }
  28% {
    transform: translateY(-7px);
    background-color: #9ecab9;
  }
  44% {
    transform: translateY(0px);
    background-color: #b5d9cb;
  }
}
.chatbot__chatname {
  font-size: 10px;
  color: #000;
  font-weight: bolder;
  letter-spacing: 1px;
}
.MuiAvatar-colorDefault {
  color: #fafafa;
  background-color: #27ae60 !important;
}
.username-main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100px;
}

.username-btn {
  margin-top: 10px;
}

.username-btns {
  display: flex;
  justify-content: space-around;
}
#username-inp-label {
  font-size: inherit;
}

.custom-reply-bot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat--time {
  margin-left: 10px;
  font-size: xx-small;
  position: absolute;
  right: 5px;
  bottom: 2px;
}
